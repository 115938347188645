<template>
  <div>
    <v-snackbar v-model="snackbar" timeout="5000" top>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-navigation-drawer
      v-model="drawer"
      permanent
      app
      expand-on-hover
      :color="'#133B7D'"
      :dark="true"
    >
      <v-list-item class="px-2">
        <v-list-item-title
          >{{ name }}
          <br />
          <span class="rolNota">{{
            rol == "ADMIN"
              ? "Admin"
              : rol == "ADMINAGENT"
              ? "Admin Agente"
              : rol == "MANAGER"
              ? "Manager Agente"
              : rol == "AGENT"
              ? "Agente"
              : rol == "ADMINCC"
              ? "Admin Call Center"
              : rol == "MANAGERCC"
              ? "Manager Call Center"
              : rol == "AGENTCC"
              ? "Agente Call Center"
              : rol == "DIGITAL"
              ? "Digital"
              : rol == "DIRECTOR"
              ? "Director"
              : rol == "DIRECTO"
              ? "Directo"
              : rol == "FINANZAS"
              ? "Finanzas"
              : rol == "MESADECONTROL"
              ? "Mesa de Control"
              : rol == "OPERACIONES"
              ? "Operaciones"
              : rol == "OPERADOR"
              ? "Operador"
              : rol == "AGENTPARTNER"
              ? "Agente Partner"
              : rol == "AUDITOR"
              ? "Gestor de auditoría"
              : rol == "SUPASISTENCIAS"
              ? "Asistencias"
              : rol == "ASISTENCIADORA"
              ? "Asistencias"
              : ""
          }}</span>
        </v-list-item-title>
      </v-list-item>

      <div>
        <v-divider></v-divider>
        <v-list dense v-for="(list, idx) in listaDesplegable" :key="idx">
          <v-list-group
            v-for="item in list.items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              v-on:click="$router.push(child.route)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>

      <v-divider></v-divider>
      <v-list dense style="cursor: pointer">
        <v-list-item v-on:click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: null,
      snackbar: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      name: localStorage.agenteName,
      drawer: true,
      listaDesplegable: null,
    };
  },
  created() {
    this.$emmited.$on("notify", (value) => {
      console.log(value);
      this.snackbar = value.open;
      this.text = value.text;
    });
  },
  mounted() {
    this.setListaDesplegable();
  },

  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },

    setListaDesplegable() {
      this.rol == "ADMIN"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Leads",
                      route: "/bradescard",
                      // route: '/proximamente',
                    },
                    {
                      title: "Leads Inbound",
                      route: "/bradescard/leads/dashboard",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Cuotas de Pólizas",
                      route: "/cuotas-hub",
                      //route: '/proximamente',
                    },
                    {
                      title: "Productos",
                      route: "/lista-productos",
                    },
                    {
                      title: "Solicitudes",
                      route: "/mis-solicitudes",
                    },
                  ],
                },
                {
                  action: "mdi-account-box-multiple-outline",
                  title: "Administración",
                  items: [
                    {
                      title: "Administrar Usuarios",
                      route: "/usuario/verAgentes",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "SLA de Solicitudes",
                      route: "/sla-solicitudes",
                    },
                    {
                      title: "Reporte gerencial Segurify",
                      route: "/reporte-gerencial",
                    },
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emisiones Asistencias Vigor",
                      route: "/reporte-emision-asistencias-vigor",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },

                    {
                      title: "Reporte Emision Incapacidad ap",
                      route: "/reporte-emision-incapacidad-ap",
                    },
                    {
                      title: "Reporte Emision vida",
                      route: "/reporte-emision-vida",
                    },
                    {
                      title: "Reporte Emision Vida + Ahorro",
                      route: "/reporte-emision-vida-ahorro",
                    },
                    {
                      title: "Reporte Emision Seguro Celular",
                      route: "/reporte-emision-celular",
                    },
                    {
                      title: "Reporte Emision Hospitalización",
                      route: "/reporte-emision-hospitalizacion",
                    },
                    {
                      title: "Reporte Emisiones Asistencias",
                      route: "/reporte-emision-asistencias",
                    },

                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Reporte cobranza Hogar AIG",
                      route: "/reporte/cobranza/aig",
                    },
                    {
                      title: "Reporte Cobranza Incapacidad ap",
                      route: "/reporte/cobranza/incapacidad-ap",
                    },

                    {
                      title: "Reporte Cobranza Vida Sura",
                      route: "/reporte/cobranza/vida-sura",
                    },
                    {
                      title: "Reporte Cobranza Metlife",
                      route: "/reporte/cobranza/vida-ahorro-metlife",
                    },
                    {
                      title: "Reporte Cobranza Seguro Celular",
                      route: "/reporte/cobranza/celular",
                    },
                    {
                      title: "Reporte Cobranza Hospitalización",
                      route: "/reporte/cobranza/hospitalizacion",
                    },
                    {
                      title: "Reporte Cobranza Asistencias",
                      route: "/reporte/cobranza/asistencias",
                    },
                    {
                      title: "Pólizas por renovar",
                      route: "/polizas-por-renovar",
                    },
                    {
                      title: "Pólizas renovadas",
                      route: "/polizas-renovadas",
                    },
                    {
                      title: "Cuotas de Pólizas Activas",
                      route: "/cobranza-polizas-activas",
                    },
                    {
                      title: "Reporte de Solicitudes",
                      route: "/reporte-solicitudes",
                    },
                    {
                      title: "Reporte de Redenciones",
                      route: "/reporte-redenciones",
                    },
                    {
                      title: "Cartera de Clientes",
                      route: "/proximamente",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "ADMINAGENT" || this.rol == "ADMINASIST"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Leads",
                      route: "/bradescard",
                      // route: '/proximamente',
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    {
                      title: "Leads Inbound",
                      route: "/bradescard/leads/dashboard",
                    },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Solicitudes",
                      route: "/mis-solicitudes",
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Cuotas de Pólizas",
                      route: "/cuotas-hub",
                      //route: '/proximamente',
                    },
                    {
                      title: "Productos",
                      route: "/lista-productos",
                    },
                  ],
                },
                {
                  action: "mdi-account-box-multiple-outline",
                  title: "Administración",
                  items: [
                    {
                      title: "Administrar Usuarios",
                      route: "/usuario/verAgentes",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "SLA de Solicitudes",
                      route: "/sla-solicitudes",
                    },
                    /*
                    {
                      title: "Reporte gerencial Segurify",
                      route: "/reporte-gerencial",
                    },
                    */
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emisiones Asistencias Vigor",
                      route: "/reporte-emision-asistencias-vigor",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },

                    {
                      title: "Reporte Emision Incapacidad ap",
                      route: "/reporte-emision-incapacidad-ap",
                    },
                    {
                      title: "Reporte Emision vida",
                      route: "/reporte-emision-vida",
                    },
                    {
                      title: "Reporte Emision Vida + Ahorro",
                      route: "/reporte-emision-vida-ahorro",
                    },
                    {
                      title: "Reporte Emision Seguro Celular",
                      route: "/reporte-emision-celular",
                    },
                    {
                      title: "Reporte Emision Hospitalización",
                      route: "/reporte-emision-hospitalizacion",
                    },

                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Pólizas por renovar",
                      route: "/polizas-por-renovar",
                    },
                    {
                      title: "Pólizas renovadas",
                      route: "/polizas-renovadas",
                    },
                    {
                      title: "Cuotas de Pólizas Activas",
                      route: "/cobranza-polizas-activas",
                    },
                    {
                      title: "Reporte de Solicitudes",
                      route: "/reporte-solicitudes",
                    },
                    {
                      title: "Reporte de Redenciones",
                      route: "/reporte-redenciones",
                    },
                    {
                      title: "Cartera de Clientes",
                      route: "/proximamente",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "ADMINAGENT"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Inicio",
                      route: "/inicio",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    // {
                    //   title: 'Mis Cotizaciones',
                    //   route: '/mis-cotizaciones',
                    // },
                    {
                      title: "Solicitudes",
                      route: "/solicitudes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Cuotas de Pólizas",
                      route: "/lista-comisiones",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-account-box-multiple-outline",
                  title: "Administración",
                  items: [
                    {
                      title: "Administrar Usuarios",
                      route: "/usuario/verAgentes",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "SLA de Solicitudes",
                      route: "/sla-solicitudes",
                    },
                    /*
                    {
                      title: "Reporte gerencial Segurify",
                      route: "/reporte-gerencial",
                    },
                    */
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte Emision Incapacidad ap",
                      route: "/reporte-emision-incapacidad-ap",
                    },
                    {
                      title: "Reporte Emision vida",
                      route: "/reporte-emision-vida",
                    },
                    {
                      title: "Reporte Emision Vida + Ahorro",
                      route: "/reporte-emision-vida-ahorro",
                    },
                    {
                      title: "Reporte Emision Seguro Celular",
                      route: "/reporte-emision-celular",
                    },
                    {
                      title: "Reporte Emision Hospitalización",
                      route: "/reporte-emision-hospitalizacion",
                    },
                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Pólizas por renovar",
                      route: "/polizas-por-renovar",
                    },
                    {
                      title: "Pólizas renovadas",
                      route: "/polizas-renovadas",
                    },
                    {
                      title: "Cuotas de Pólizas Activas",
                      route: "/cobranza-polizas-activas",
                    },
                    {
                      title: "Cartera de Clientes",
                      route: "/proximamente",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "MANAGER"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Inicio",
                      route: "/inicio",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    // {
                    //   title: 'Mis Cotizaciones',
                    //   route: '/mis-cotizaciones',
                    // },
                    {
                      title: "Solicitudes",
                      route: "/solicitudes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Cuotas de Pólizas",
                      route: "/lista-comisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Leads",
                      route: "/bradescard",
                      // route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-account-box-multiple-outline",
                  title: "Administración",
                  items: [
                    {
                      title: "Administrar Usuarios",
                      route: "/usuario/verAgentes",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "SLA de Solicitudes",
                      route: "/sla-solicitudes",
                    },
                    /*
                    {
                      title: "Reporte gerencial Segurify",
                      route: "/reporte-gerencial",
                    },
                    */
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emisión Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte Emision Incapacidad ap",
                      route: "/reporte-emision-incapacidad-ap",
                    },
                    {
                      title: "Reporte Emision vida",
                      route: "/reporte-emision-vida",
                    },
                    {
                      title: "Reporte Emision Vida + Ahorro",
                      route: "/reporte-emision-vida-ahorro",
                    },
                    {
                      title: "Reporte Emision Seguro Celular",
                      route: "/reporte-emision-celular",
                    },
                    {
                      title: "Reporte Emision Hospitalización",
                      route: "/reporte-emision-hospitalizacion",
                    },
                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Pólizas por renovar",
                      route: "/polizas-por-renovar",
                    },
                    {
                      title: "Pólizas renovadas",
                      route: "/polizas-renovadas",
                    },
                    {
                      title: "Cuotas de Pólizas Activas",
                      route: "/cobranza-polizas-activas",
                    },
                    {
                      title: "Cartera de Clientes",
                      route: "/proximamente",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "AGENT"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Inicio",
                      route: "/inicio",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Productos",
                      route: "/lista-productos",
                      //route: '/proximamente',
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Cuotas de Pólizas",
                      route: "/cuotas-hub",
                      //route: '/proximamente',
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "ADMINCC"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  items: [
                    {
                      title: "Leads",
                      route: "/bradescard",
                      // route: '/proximamente',
                    },
                    {
                      title: "Leads Inbound",
                      route: "/bradescard/leads/dashboard",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Cuotas de Pólizas",
                      route: "/cuotas-hub",
                      //route: '/proximamente',
                    },
                    {
                      title: "Solicitudes",
                      route: "/mis-solicitudes",
                    },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                    },
                  ],
                  title: "Cuenta",
                },
                {
                  action: "mdi-account-box-multiple-outline",
                  title: "Administración",
                  items: [
                    {
                      title: "Administrar Usuarios",
                      route: "/usuario/verAgentes",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    /*
                    {
                      title: "Reporte gerencial Segurify",
                      route: "/reporte-gerencial",
                    },
                    */
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte Emision Incapacidad ap",
                      route: "/reporte-emision-incapacidad-ap",
                    },
                    {
                      title: "Reporte Emision vida",
                      route: "/reporte-emision-vida",
                    },
                    {
                      title: "Reporte Emision Vida + Ahorro",
                      route: "/reporte-emision-vida-ahorro",
                    },
                    {
                      title: "Reporte Emision Seguro Celular",
                      route: "/reporte-emision-celular",
                    },
                    {
                      title: "Reporte Emision Hospitalización",
                      route: "/reporte-emision-hospitalizacion",
                    },
                    {
                      title: "Reporte Emisiones Asistencias",
                      route: "/reporte-emision-asistencias",
                    },
                    {
                      title: "Reporte de Solicitudes",
                      route: "/reporte-solicitudes",
                    },
                    {
                      title: "Reporte de Redenciones",
                      route: "/reporte-redenciones",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "MANAGERCC"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  items: [
                    {
                      title: "Inicio",
                      route: "/inicio",
                    },
                    {
                      title: "Leads",
                      route: "/bradescard",
                      // route: '/proximamente',
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Cuotas de Pólizas",
                      route: "/cuotas-hub",
                      //route: '/proximamente',
                    },
                    // {
                    //   title: 'Leads',
                    //   route: '/bradescard'
                    //   // route: '/proximamente',
                    // }
                  ],
                  title: "Cuenta",
                },
                {
                  action: "mdi-account-box-multiple-outline",
                  title: "Administración",
                  items: [
                    {
                      title: "Administrar Usuarios",
                      route: "/usuario/verAgentes",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "Reporte gerencial Segurify",
                      route: "/reporte-gerencial",
                    },
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte Emision Incapacidad ap",
                      route: "/reporte-emision-incapacidad-ap",
                    },
                    {
                      title: "Reporte Emision vida",
                      route: "/reporte-emision-vida",
                    },
                    {
                      title: "Reporte Emision Vida + Ahorro",
                      route: "/reporte-emision-vida-ahorro",
                    },
                    {
                      title: "Reporte Emision Seguro Celular",
                      route: "/reporte-emision-celular",
                    },
                    {
                      title: "Reporte Emision Hospitalización",
                      route: "/reporte-emision-hospitalizacion",
                    },
                    {
                      title: "Pólizas Emitidas",
                      route: "/polizas-emitidas",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "AGENTCC"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    // {
                    //   title: 'Inicio',
                    //   route: '/inicio' },
                    {
                      title: "Leads",
                      route: "/bradescard",
                      // route: '/proximamente',
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "DIGITAL"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Inicio",
                      route: "/inicio",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    // {
                    //   title: 'Mis Cotizaciones',
                    //   route: '/mis-cotizaciones',
                    // },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte Leads Segurify",
                      route: "/administrador/reporte-clientes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte de Leads Autos",
                      route: "/administrador/reporte-actualSales",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte de Leads Movilidad",
                      route: "/administrador/reporte-sura-movilidad",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte de Leads Rastreator",
                      route: "/administrador/reporte-leads-rastreator",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte de Leads Ahorro",
                      route: "/administrador/reporte-ahorro",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte de Leads Tractos",
                      route: "/proximamente",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte de Leads SURA AlaSegura",
                      route: "/proximamente",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte de Leads SGMM Segurify",
                      route: "/administrador/reporte-sgmm",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte Abandono Leads",
                      route: "/landings/reporteAbandonoLeads",
                      //route: '/proximamente',
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "DIRECTOR"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Inicio",
                      route: "/inicio",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    {
                      title: "Solicitudes",
                      route: "/solicitudes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Cuotas de Pólizas",
                      route: "/cuotas-hub",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-account-box-multiple-outline",
                  title: "Administración",
                  items: [
                    {
                      title: "Administrar Usuarios",
                      route: "/usuario/verAgentes",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    // {
                    //   title: 'Solicitudes con Pólizas',
                    //   route: '/solicitudes-con-polizas',
                    // },
                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Pólizas por renovar",
                      route: "/polizas-por-renovar",
                    },
                    {
                      title: "Pólizas renovadas",
                      route: "/polizas-renovadas",
                    },
                    {
                      title: "Cuotas de Pólizas Activas",
                      route: "/cobranza-polizas-activas",
                    },
                    // {
                    //   title: 'Polizas con cobranza Vencida',
                    //   route: '/proximamente',
                    // },
                    // {
                    //   title: 'Cartera de Agentes',
                    //   route: '/cartera-agentes'
                    // },
                    {
                      title: "Cartera de Clientes",
                      route: "/proximamente",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "DIRECTO"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Inicio",
                      route: "/inicio",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    // {
                    //   title: 'Mis Cotizaciones',
                    //   route: '/mis-cotizaciones',
                    // },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    // {
                    //   title: 'Solicitudes con Pólizas',
                    //   route: '/solicitudes-con-polizas',
                    // },
                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Pólizas por renovar",
                      route: "/polizas-por-renovar",
                    },
                    {
                      title: "Pólizas renovadas",
                      route: "/polizas-renovadas",
                    },
                    {
                      title: "Cuotas de Pólizas Activas",
                      route: "/cobranza-polizas-activas",
                    },
                    // {
                    //   title: 'Polizas con cobranza Vencida',
                    //   route: '/proximamente',
                    // },
                    // {
                    //   title: 'Cartera de Agentes',
                    //   route: '/cartera-agentes'
                    // },
                    {
                      title: "Cartera de Clientes",
                      route: "/proximamente",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "FINANZAS"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Inicio",
                      route: "/inicio",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    // {
                    //   title: 'Mis Cotizaciones',
                    //   route: '/mis-cotizaciones',
                    // },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    // {
                    //   title: 'Solicitudes con Pólizas',
                    //   route: '/solicitudes-con-polizas',
                    // },
                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte Emisiones Asistencias",
                      route: "/reporte-emision-asistencias",
                    },
                    {
                      title: "Pólizas por renovar",
                      route: "/polizas-por-renovar",
                    },
                    {
                      title: "Pólizas renovadas",
                      route: "/polizas-renovadas",
                    },
                    {
                      title: "Cuotas de Pólizas Activas",
                      route: "/cobranza-polizas-activas",
                    },
                    {
                      title: "Reporte cobranza Hogar AIG",
                      route: "/reporte/cobranza/aig",
                    },
                    {
                      title: "Reporte Cobranza Incapacidad ap",
                      route: "/reporte/cobranza/incapacidad-ap",
                    },
                    {
                      title: "Reporte Cobranza Vida Sura",
                      route: "/reporte/cobranza/vida-sura",
                    },
                    {
                      title: "Reporte Cobranza Metlife",
                      route: "/reporte/cobranza/vida-ahorro-metlife",
                    },
                    {
                      title: "Reporte Cobranza Seguro Celular",
                      route: "/reporte/cobranza/celular",
                    },
                    {
                      title: "Reporte Cobranza Hospitalización",
                      route: "/reporte/cobranza/hospitalizacion",
                    },
                    {
                      title: "Reporte Cobranza Asistencias",
                      route: "/reporte/cobranza/asistencias",
                    },
                    // {
                    //   title: 'Polizas con cobranza Vencida',
                    //   route: '/proximamente',
                    // },
                    // {
                    //   title: 'Cartera de Agentes',
                    //   route: '/cartera-agentes',
                    // },
                    {
                      title: "Cartera de Clientes",
                      route: "/proximamente",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "OPERACIONES" ||
          this.rol == "MESADECONTROL" ||
          this.rol == "OPERACIONESASIST" ||
          this.rol == "MESACONTROLASIST"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Inicio",
                      route: "/inicio",
                    },
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    ...(this.rol == "operaciones" && {
                      title: "Leads Inbound",
                      route: "/bradescard/leads/dashboard",
                    }),
                    {
                      title: "Solicitudes",
                      route: "/mis-solicitudes",
                    },
                    // {
                    //   title: 'Mis Cotizaciones',
                    //   route: '/mis-cotizaciones',
                    // },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                      //route: '/proximamente',
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Cuotas de Pólizas",
                      route: "/cuotas-hub",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-account-box-multiple-outline",
                  title: "Administración",
                  items: [
                    {
                      title: "Administrar Usuarios",
                      route: "/usuario/verAgentes",
                      //route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "SLA de Solicitudes",
                      route: "/sla-solicitudes",
                    },
                    /*
                    {
                      title: "Reporte gerencial Segurify",
                      route: "/reporte-gerencial",
                    },
                    */
                    // {
                    //   title: 'Solicitudes con Pólizas',
                    //   route: '/solicitudes-con-polizas',
                    // },
                    // {
                    //   title: 'Cartera de Agentes',
                    //   route: '/cartera-agentes',
                    // },
                    {
                      title: "Reporte marcacion",
                      route: "/reporte-marcacion",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte Emisiones Asistencias",
                      route: "/reporte-emision-asistencias",
                    },
                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Pólizas por renovar",
                      route: "/polizas-por-renovar",
                    },
                    {
                      title: "Pólizas renovadas",
                      route: "/polizas-renovadas",
                    },
                    {
                      title: "Cuotas de Pólizas Activas",
                      route: "/cobranza-polizas-activas",
                    },
                    // {
                    //   title: 'Polizas con cobranza Vencida',
                    //   route: '/proximamente',
                    // },
                    {
                      title: "Reporte de Solicitudes",
                      route: "/reporte-solicitudes",
                    },
                    {
                      title: "Reporte de Redenciones",
                      route: "/reporte-redenciones",
                    },
                    {
                      title: "Cartera de Clientes",
                      route: "/proximamente",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "OPERADOR" || this.rol == "OPERADORASIST"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Leads Inbound",
                      route: "/bradescard/leads/dashboard",
                    },
                    {
                      title: "Solicitudes",
                      route: "/mis-solicitudes",
                    },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "Reporte Emisiones Asistencias",
                      route: "/reporte-emision-asistencias",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "AGENTPARTNER"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-clipboard-text-search-outline",
                  active: true,
                  title: "Reporte de Partners",
                  items: [
                    // {
                    //   title: 'Mis Cotizaciones',
                    //   route: '/mis-cotizaciones',
                    // },
                    {
                      title: "Reporte de Emisiones tuSeguro.com",
                      route: "/administrador/reporte-tuSeguro-emisiones",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte de Leads tuSeguro.com",
                      route: "/administrador/reporte-tuSeguro-leads",
                      //route: '/proximamente',
                    },
                    // {
                    //   title: 'Reporte Toques Call Center tuSeguro.com',
                    //   //route: '/callCenter/reporteLeadsTuseguro',
                    //   route: '/proximamente',
                    // },
                    {
                      title: "Reporte Leads-Oportunidades tuSeguro.com",
                      route: "/callCenter/reporteLeadsTuseguro",
                      //route: '/proximamente',
                    },
                    {
                      title: "Reporte Abandono Leads TuSeguro",
                      route: "/landings/reporteAbandonoLeads/tuSeguro",
                      //route: '/proximamente',
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "AUDITOR"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Leads",
                      route: "/bradescard",
                      // route: '/proximamente',
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    /*
                    {
                      title: "Reporte gerencial Segurify",
                      route: "/reporte-gerencial",
                    },
                    */
                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte Emision Incapacidad ap",
                      route: "/reporte-emision-incapacidad-ap",
                    },
                    {
                      title: "Reporte Emision Seguro Celular",
                      route: "/reporte-emision-celular",
                    },
                    {
                      title: "Reporte Emision Hospitalización",
                      route: "/reporte-emision-hospitalizacion",
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                    },
                    {
                      title: "Reporte Emision vida",
                      route: "/reporte-emision-vida",
                    },
                    {
                      title: "Reporte Emision Vida + Ahorro",
                      route: "/reporte-emision-vida-ahorro",
                    },
                    {
                      title: "Reporte cobranza Hogar AIG",
                      route: "/reporte/cobranza/aig",
                    },
                    {
                      title: "Reporte Cobranza Incapacidad ap",
                      route: "/reporte/cobranza/incapacidad-ap",
                    },
                    {
                      title: "Reporte Cobranza Vida Sura",
                      route: "/reporte/cobranza/vida-sura",
                    },
                    {
                      title: "Reporte Cobranza Metlife",
                      route: "/reporte/cobranza/vida-ahorro-metlife",
                    },
                    {
                      title: "Reporte Cobranza Seguro Celular",
                      route: "/reporte/cobranza/celular",
                    },
                    {
                      title: "Reporte Cobranza Hospitalización",
                      route: "/reporte/cobranza/hospitalizacion",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "BACKOFFICE"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "Reporte gerencial Segurify",
                      route: "/reporte-gerencial",
                    },
                    {
                      title: "Pólizas emitidas",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Reporte Emision Hogar",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte Emisiones Hogar APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte Emision Incapacidad ap",
                      route: "/reporte-emision-incapacidad-ap",
                    },
                    {
                      title: "Reporte Emision Seguro Celular",
                      route: "/reporte-emision-celular",
                    },
                    {
                      title: "Reporte Emision Hospitalización",
                      route: "/reporte-emision-hospitalizacion",
                    },
                    {
                      title: "Pólizas",
                      route: "/mis-emisiones",
                    },
                    {
                      title: "Reporte Emision vida",
                      route: "/reporte-emision-vida",
                    },
                    {
                      title: "Reporte Emision Vida + Ahorro",
                      route: "/reporte-emision-vida-ahorro",
                    },
                    {
                      title: "Reporte cobranza Hogar AIG",
                      route: "/reporte/cobranza/aig",
                    },
                    {
                      title: "Reporte Cobranza Incapacidad ap",
                      route: "/reporte/cobranza/incapacidad-ap",
                    },
                    {
                      title: "Reporte Cobranza Vida Sura",
                      route: "/reporte/cobranza/vida-sura",
                    },
                    {
                      title: "Reporte Cobranza Metlife",
                      route: "/reporte/cobranza/vida-ahorro-metlife",
                    },
                    {
                      title: "Reporte Cobranza Seguro Celular",
                      route: "/reporte/cobranza/celular",
                    },
                    {
                      title: "Reporte Cobranza Hospitalización",
                      route: "/reporte/cobranza/hospitalizacion",
                    },
                    {
                      title: "Reporte Emisiones Asistencias",
                      route: "/reporte-emision-asistencias",
                    },
                    {
                      title: "Reporte de Solicitudes",
                      route: "/reporte-solicitudes",
                    },
                    {
                      title: "Reporte de Redenciones",
                      route: "/reporte-redenciones",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "SUPASISTENCIAS"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [
                    {
                      title: "Solicitudes",
                      route: "/mis-solicitudes",
                    },
                    {
                      title: "Clientes",
                      route: "/lista-clientes",
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "Reporte Emisiones Asistencias",
                      route: "/reporte-emision-asistencias",
                    },
                    {
                      title: "Reporte Emisiones Asistencias Vigor",
                      route: "/reporte-emision-asistencias-vigor",
                    },
                    {
                      title: "Reporte Cobranza Asistencias",
                      route: "/reporte/cobranza/asistencias",
                    },
                    {
                      title: "Reporte de Solicitudes",
                      route: "/reporte-solicitudes",
                    },
                    {
                      title: "Reporte de Redenciones",
                      route: "/reporte-redenciones",
                    },
                  ],
                },
              ],
            },
          ])
        : this.rol == "ASISTENCIADORA"
        ? (this.listaDesplegable = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Cuenta",
                  items: [],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes",
                  items: [
                    {
                      title: "Reporte Emisiones Asistencias",
                      route: "/reporte-emision-asistencias",
                    },
                    {
                      title: "Reporte Cobranza Asistencias",
                      route: "/reporte/cobranza/asistencias",
                    },
                  ],
                },
              ],
            },
          ])
        : "";
    },
  },
};
</script>
<style>
.v-carousel__controls {
  bottom: 12px;
}

.rolNota {
  font-size: 12px;
  color: rgb(192, 192, 192);
}

.v-list-item__title {
  white-space: normal !important;
}
</style>
