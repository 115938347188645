<template>
  <v-app>
    <v-row class="mb-10" dense align="center">
      <v-img
        :max-height="height_log1"
        :max-width="width_log1"
        :src="logo_1"
      ></v-img>
      <h2 class="ml-5">Clientes bradescard</h2>
      <v-spacer />
      <v-img
        :max-height="width_log2"
        :max-width="height_log2"
        :src="logo_2"
      ></v-img>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BuscarCliente @openSolicitudForm="showSolicitudDialog = true" />
      </v-col>
    </v-row>

    <solicitud-dialog
      v-if="showSolicitudDialog"
      :show="showSolicitudDialog"
      route="/v1/solicitud/manual"
      categoria="Redención sin Emisión"
      @close="closeSolicitudDialog"
    ></solicitud-dialog>
  </v-app>
</template>

<script>
import BuscarCliente from "./BuscarCliente.vue";
import solicitudDialogForm from "../../../components/solicitudes/solicitudDialogForm.vue";

import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-bradescard-horizontal.png";

export default {
  components: {
    BuscarCliente,
    "solicitud-dialog": solicitudDialogForm,
  },
  data() {
    return {
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,

      logo_2: LogoBradescard,
      width_log2: 80,
      height_log2: 250,

      showSolicitudDialog: false,
    };
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
    puedeCrear() {
      const rolesValidos = [
        "ADMINCC",
        "MANAGERCC",
        "DIRECTO",
        "ADMIN",
        "ADMINAGENT",
        "MANAGER",
      ];
      return !!rolesValidos.find((e) => e == this.rol);
    },
  },
  methods: {
    closeSolicitudDialog(event) {
      this.showSolicitudDialog = false;
      if (
        event &&
        event.data &&
        event.data.data.solicitud &&
        event.data.data.solicitud.emisionId
      ) {
        this.$toast.info("Los datos del cliente coinciden con la emisión " + event.data.data.solicitud.emisionId);
        this.$router.push(`/emision/${event.data.data.solicitud.emisionId}`);
      }
    },
  },
};
</script>
