<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Solicitudes</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="showCreacion = true" color="primary" small>Crear</v-btn> -->
      </v-toolbar>

      <v-divider></v-divider>

      <v-data-table
        :headers="columns"
        :items="solicitudes"
        item-key="name"
        class="elevation-1"
        :loading="loading"
        loading-text="Cargando..."
        no-data-text="Sin solicitudes"
        dense
      >
        <template v-slot:item.accion="{ item }">
          <v-icon small class="mr-2" @click="goToSolicitud(item.id)">
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <solicitud-dialog
      v-if="showCreacion"
      :show="showCreacion"
      :cliente="cliente"
      categoria="Redención Normal"
      route="/v1/solicitud/manual"
      @close="showCreacion = false"
    ></solicitud-dialog>
  </div>
</template>

<script>
import { getSolicitudesByIdCliente } from "@/services/solicitudService";
import solicitudDialogForm from "./solicitudDialogForm.vue";

export default {
  components: {
    "solicitud-dialog": solicitudDialogForm,
  },

  props: {
    cliente: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        { text: "ID", value: "id" },
        { text: "Nombre", value: "titulo" },
        { text: "Fecha", value: "fechaCreacion" },
        { text: "Status", value: "status" },
        { text: "Tipo", value: "tipo" },
        { text: "Categoría", value: "categoria" },
        { text: "Ver", value: "accion", sortable: false },
      ],
      solicitudes: [],
      loading: false,
      showCreacion: false,
    };
  },

  mounted() {
    this.getSolicitudes();
  },

  methods: {
    getSolicitudes() {
      if (this.cliente) {
        this.getSolicitudesCliente();
      }
    },

    async getSolicitudesCliente() {
      this.loading = true;
      try {
        const response = await getSolicitudesByIdCliente(this.cliente.id);
        this.solicitudes = response.solicitudes;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    goToSolicitud(id) {
      this.$router.push(`/solicitud/${id}`);
    },
  },
};
</script>
