import { mainAxios } from "../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "./mainService";

export const getBinesTarjetas = async () => {
  try {
    const serverResponse = await mainAxios.post(
      `/v1/socioTarjetas/list`,
      {},
      getDefaultHeaders()
    );

    return serverResponse.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
