<template>
  <v-dialog v-model="show" persistent max-width="750">
    <v-card>
      <v-toolbar color="primary" dense dark flat tile>
        <v-toolbar-title>
          <span>Nueva solicitud</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <solicitudForm
          :emision="emision"
          :cliente="cliente"
          :solicitud="solicitud"
          :route="route"
          :categoria="categoria"
          @cancel="$emit('close', $event)"
        ></solicitudForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import solicitudForm from "./solicitudForm.vue";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    emision: {
      type: Object,
    },
    cliente: {
      type: Object,
    },
    solicitud: {
      type: Object,
    },
    route: {
      type: String,
    },
    categoria: {
      type: String,
    },
  },

  components: {
    solicitudForm,
  },
};
</script>
