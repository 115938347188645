<template>
  <v-app>
    <v-row class="mb-10" dense align="center">
      <v-img
        :max-height="width_log2"
        :max-width="height_log2"
        :src="logo_2"
      ></v-img>
      <h2 class="ml-5">Solcitudes</h2>
      <v-spacer />
    </v-row>
    <v-row>
      <v-row
        v-if="
          currentRol == 'ADMIN' ||
            currentRol == 'OPERADORASIST' ||
            currentRol == 'OPERADOR' ||
            currentRol == 'MESADECONTROL' ||
            currentRol == 'MESACONTROLASIST' ||
            currentRol == 'ADMINCC' ||
            currentRol == 'SUPASISTENCIAS'
        "
      >
        <v-col cols="12" style="text-align: end; padding-right: 24px;">
          <v-btn
            color="primary"
            style="align-items: right"
            class="ml-11 mt-2"
            @click="getInspirumBalanceMethod"
          >
            Consultar saldo Inspirium
          </v-btn>
          <v-btn
            color="primary"
            @click="(showCreateModal = true), (route = '/v1/solicitud/manual')"
            style="align-items: right"
            class="ml-11 mt-2"
          >
            Crear
          </v-btn>
        </v-col>
      </v-row>
      <v-col cols="12">
        <BuscarSolicitud />
      </v-col>
    </v-row>
    <solicitudDialogForm
      :show="showCreateModal"
      @close="showCreateModal = false"
      :route="route"
    ></solicitudDialogForm>
  </v-app>
</template>

<script>
import BuscarSolicitud from "./BuscarSolicitud.vue";

import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-bradescard-horizontal.png";
import solicitudDialogForm from "@/components/solicitudes/solicitudDialogForm.vue";
import { getIspiriumBalance } from "@/services/InspiriumService.js";

export default {
  components: {
    BuscarSolicitud,
    solicitudDialogForm,
  },
  data() {
    return {
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,
      logo_2: LogoBradescard,
      width_log2: 80,
      height_log2: 250,
      showCreateModal: false,
      route: "",
      showInspiriumBalanceModal: false,
    };
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
    currentRol() {
      let roles = [];
      let CurrentRoles = JSON.parse(localStorage.getItem("roles"));
      CurrentRoles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
  },
  methods: {
    leadCreado() {
      this.showCreacion = false;
      this.bus.$emit("reload");
    },
    async getInspirumBalanceMethod() {
      const response = await getIspiriumBalance();
      this.$toast.info(
        "El saldo restante es de: " + this.formatCurrency(response.balance)
      );
    },
    formatCurrency(amount) {
      return amount.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
  },
};
</script>
