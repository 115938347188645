import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/pages/Login.vue";
// import Home from '../views/pages/Home.vue'
import Terminos from "../views/pages/legales/Terminos.vue";
import Aviso from "../views/pages/legales/Aviso.vue";
import Cotiza from "../views/pages/Cotiza.vue";
import MisCotizaciones from "../views/pages/MisCotizaciones.vue";
import MisEmisiones from "../views/pages/MisEmisiones.vue";
import Proximamente from "../views/pages/Proximamente.vue";
import CotizaHub from "../views/pages/CotizaHub.vue";
import HubAdministrador from "../views/pages/administrador/HubAdministrador.vue";
import ReporteCotizaciones from "../views/pages/administrador/reportes/Cotizaciones.vue";
import ReporteCotizacionesAgentes from "../views/pages/administrador/reportes/CotizacionesAgentes.vue";
import ReporteEmisionesAgentes from "../views/pages/administrador/reportes/EmisionesAgentes.vue";
import ReporteClientes from "../views/pages/administrador/reportes/Clientes.vue";
import ReporteLeadsRastreator from "../views/pages/administrador/reportes/ReporteLeadsRastreator.vue";
import ReporteTuSeguroLeads from "../views/pages/administrador/reportes/TuSeguroLeads.vue";
import ReporteTuSeguroEmisiones from "../views/pages/administrador/reportes/TuSeguroEmisiones.vue";
import ReporteActualSales from "../views/pages/administrador/reportes/ActualSales.vue";
import ReporteAhorro from "../views/pages/administrador/reportes/Ahorro.vue";
import ReporteWse from "../views/pages/administrador/reportes/Wse.vue";
import EmisionesOffline from "../views/pages/administrador/reportes/EmisionesOffline.vue";
import EmisionesOfflineCallCenter from "../views/pages/administrador/reportes/EmisionesOfflineCallCenter.vue";
import CargaEmisiones from "../views/pages/administrador/reportes/CargaEmisiones.vue";
import Emite from "../views/pages/Emite.vue";
import Pago from "../views/pages/Pago.vue";
import EmisionDetalle from "../views/pages/emision/EmisionDetalle.vue";
import EditLead from "../views/pages/callCenter/EditLead.vue";
import ReporteLeads from "../views/pages/callCenter/ReporteLeads.vue";
import ReporteLeadsTuSeguro from "../views/pages/callCenter/ReporteLeadsTuSeguro.vue";
import ReporteCallCenter from "../views/pages/callCenter/ReporteCallCenter.vue";
import AbandonoLeads from "../views/pages/administrador/landings/ReporteAbandonoLeads.vue";
import AbandonoLeadsTuSeguro from "../views/pages/administrador/landings/ReporteAbandonoLeadsTuSeguro.vue";
//import EmisionDetalle from '../views/pages/callCenter/.vue';
import CotizacionDetail from "../views/pages/cotizacion/CotizacionDetail.vue";
import ConfirmaGNP from "../views/pages/ConfirmaGNP.vue";
import CrearUsuario from "../views/pages/administrador/usuarios/CrearUsuario.vue";
import MiCuenta from "../views/pages/administrador/usuarios/MiCuenta.vue";
import VisualizacionMiCuenta from "../views/pages/administrador/usuarios/VisualizacionMiCuenta.vue";
import VerAgentes from "../views/pages/administrador/usuarios/TablaUsuarios.vue";
import CotizaAgentes from "../views/pages/administrador/usuarios/CotizaComoAgente.vue";
import SolicitudesHub from "../views/pages/solicitudes/SolicitudesHub.vue";
import SolicitudForm from "../views/pages/solicitudes/SolicitudForm.vue";
import SeguimientoSolicitud from "../views/pages/solicitudes/SeguimientoSolicitud.vue";
import MisSolicitudesOperacion from "../views/pages/solicitudes/MisSolicitudesOperacion.vue";
import TarjetaComercial from "../views/pages/solicitudes/TarjetaComercial.vue";
import InfoRequeridaSolicitudes from "../views/pages/solicitudes/InfoRequeridaSolicitudes.vue";
import ActualizarSolicitud from "../views/pages/solicitudes/ActualizarSolicitud.vue";
import ActualizarEmision from "../views/pages/emision/ActualizarEmision.vue";
import CrearPoliza from "../views/pages/emision/CrearPoliza.vue";
// reeportees sgmm
import ReporteSGMM from "../views/pages/masivos/ReporteSGMM.vue";
import ReporteSuraSGMM from "../views/pages/masivos/ReporteSuraSGMM.vue";
import ReporteSuraMovilidad from "../views/pages/masivos/ReporteSuraMovilidad.vue";
// pagina whatsapp
import WhatsAppComponent from "../views/pages/whatsapp/WhatsAppComponent.vue";
//MODULO PRODUCTOS
import VistaListaProductos from "../views/pages/productos/VistaListaProductos.vue";
import VistaVisualizacionProductos from "../views/pages/productos/VistaVisualizacionProductos.vue";
import RegistroProductos from "../views/pages/productos/RegistroProductos.vue";
import EdicionProductos from "../views/pages/productos/EdicionProductos.vue";
//MODULO COMISIONES
import VistaListaComisiones from "../views/pages/comisiones/VistaListaComisiones.vue";
import VistaVisualizacionComisiones from "../views/pages/comisiones/VistaVisualizacionComisiones.vue";
import VistaRegistroComisiones from "../views/pages/comisiones/VistaRegistroComisiones.vue";
//MODULO CUOTAS
import CuotasHub from "../views/pages/cuotas/CuotasHub.vue";
//lista de clientes
// import ListaClientes from "../views/pages/clientes/ListaClientes.vue";
import ClienteDetalle from "../views/pages/clientes/ClienteDetalle.vue";
import ActualizarClientes from "../views/pages/clientes/ActualizarClientes.vue";
//MODULO CLIENTES
import VistaListaClientes from "../views/pages/clientes/VistaListaClientes.vue";
import VistaVisualizacionClientes from "../views/pages/clientes/VistaVisualizacionClientes.vue";
import CrearClientes from "../views/pages/clientes/CrearClientes.vue";
import VistaEditarComisiones from "../views/pages/comisiones/VistaEditarComisiones.vue";
//NUEVOS REPORTES
import SolicitudesConPolizas from "../views/pages/administrador/reportes/SolicitudesConPolizas.vue";
import SolicitudesPendientesAprobacion from "../views/pages/administrador/reportes/SolicitudesPendientesAprobacion.vue";
import SlaSolicitudes from "../views/pages/administrador/reportes/SlaSolicitudes.vue";
import ReporteGerencial from "../views/pages/administrador/reportes/ReporteGerencial.vue";
import PolizasPorRenovar from "../views/pages/administrador/reportes/PolizasPorRenovar.vue";
import PolizasRenovadas from "../views/pages/administrador/reportes/PolizasRenovadas.vue";
import PolizasEmitidas from "../views/pages/administrador/reportes/PolizasEmitidas.vue";
import ReporteMarcacion from "../views/pages/administrador/reportes/ReporteMarcacion.vue";
import ReporteEmisionesAIG from "../views/pages/administrador/reportes/ReporteEmisionesAIG.vue";
import ReporteEmisionesAIGFallidas from "../views/pages/administrador/reportes/ReporteEmisionesAIGFallidas.vue";
import ReporteEmisionesAPP from "../views/pages/administrador/reportes/ReporteEmisionesAPP.vue";
import CobranzaPolizasActivas from "../views/pages/administrador/reportes/CobranzaPolizasActivas.vue";
import CuotasPagadas from "../views/pages/administrador/reportes/CuotasPagadas.vue";
import CuotasCanceladas from "../views/pages/administrador/reportes/CuotasCanceladas.vue";
import CuotasVencidas from "../views/pages/administrador/reportes/CuotasVencidas.vue";
import CarteraAgentes from "../views/pages/administrador/reportes/CarteraAgentes.vue";
//REPORTES LEADS TRACTOS (NUEVO)
import reporteLeadsTractos from "../views/pages/administrador/reportes/ReporteLeadsTractos.vue";

// Modulos bradescard
import ViewBradescard from "./../views/pages/bradescard/Leads.vue";
import LeadBradescard from "./../views/pages/bradescard/admin/leads/ListaLeads.vue";
import DashboardLeads from "./../views/pages/bradescard/admin/leads/DashboardLeads.vue";
import DashboardPolizas from "./../views/pages/bradescard/admin/leads/DashboardPolizas.vue";
import DetalleLeadsBradescard from "./../views/pages/bradescard/DetallesLeads.vue";

import ReporteEmisionesAp from "../views/pages/administrador/reportes/ReporteIncapacidadAP.vue";
import ReporteEmisionesVida from "../views/pages/administrador/reportes/ReporteEmisionVida.vue";
import ReporteCobranzaAIG from "../views/pages/administrador/reportes/ReporteCobranzaAIG.vue";
import IndexSolicitudes from "../views/pages/bradescard/solicitudes/IndexSolicitudes.vue";
import ReporteEmisionesVidaAhorro from "../views/pages/administrador/reportes/ReporteEmisionVidaAhorro.vue";

import Main from "~/views/templates/main.vue";

import VerCliente from "../views/pages/clientes/VerCliente.vue";
import DashboardCliente from "../views/pages/clientes/DashboardClientes.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "",
    component: Main,
    redirect: "home",
    children: [
      {
        path: "/inicio",
        name: "home",
        redirect: "bradescard",
        // component: Home,
        // meta: {
        //   requiresAuth: true,
        // },
      },
      {
        path: "/terminos-y-condiciones",
        name: "terminos",
        component: Terminos,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/aviso-de-privacidad",
        name: "aviso",
        component: Aviso,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotiza/:aseguradora",
        name: "cotiza",
        component: Cotiza,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/registroWhatsapp",
        name: "whatsapp",
        component: WhatsAppComponent,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotiza/:aseguradora/:uuid",
        name: "cotizaUuid",
        component: Cotiza,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotiza-hub",
        name: "cotiza-hub",
        component: CotizaHub,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/mis-cotizaciones",
        name: "mis-cotizaciones",
        component: MisCotizaciones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/mis-emisiones",
        name: "mis-emisiones",
        component: MisEmisiones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/home",
        name: "home-administrador",
        component: HubAdministrador,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-cotizaciones",
        name: "reporte-cotizaciones",
        component: ReporteCotizaciones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-cotizaciones-agentes",
        name: "reporte-cotizaciones-agentes",
        component: ReporteCotizacionesAgentes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-emisiones-agentes",
        name: "reporte-emisiones-agentes",
        component: ReporteEmisionesAgentes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-clientes",
        name: "reporte-clientes",
        component: ReporteClientes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-leads-rastreator",
        name: "reporte-leads-rastreator",
        component: ReporteLeadsRastreator,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-tuSeguro-leads",
        name: "reporte-tuseguro-leads",
        component: ReporteTuSeguroLeads,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-tuSeguro-emisiones",
        name: "reporte-tuseguro-emisiones",
        component: ReporteTuSeguroEmisiones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-actualSales",
        name: "reporte-actualSales",
        component: ReporteActualSales,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-ahorro",
        name: "reporte-ahorro",
        component: ReporteAhorro,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-wse",
        name: "reporte-wse",
        component: ReporteWse,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-emisiones-offline",
        name: "reporte-emisiones-offline",
        component: EmisionesOffline,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callcenter/reporte-emisiones-offline",
        name: "reporte-emisiones-offlineCallCenter",
        component: EmisionesOfflineCallCenter,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/carga-emisiones",
        name: "carga-emisiones",
        component: CargaEmisiones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/editar-emision/:emision",
        name: "editar-emisiones",
        props: true,
        component: CargaEmisiones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/emite/:request",
        name: "emite",
        props: true,
        component: Emite,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/emite/:request/:uuid",
        name: "emiteUUID",
        props: true,
        component: Emite,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pago/:uuid",
        name: "pago-pre-emision",
        props: true,
        component: Pago,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/emision/:uuid",
        name: "emision",
        props: true,
        component: EmisionDetalle,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callCenter/lead",
        name: "searchLead",
        component: EditLead,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callCenter/reporteLeads",
        name: "reporteLeads",
        component: ReporteLeads,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callCenter/reporteLeadsTuseguro",
        name: "reporteLeadsTuSeguro",
        component: ReporteLeadsTuSeguro,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callCenter/reporteToquesCC",
        name: "reporteCC",
        component: ReporteCallCenter,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/landings/reporteAbandonoLeads",
        name: "ReporteAbandonoLeads",
        props: true,
        component: AbandonoLeads,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/landings/reporteAbandonoLeads/tuSeguro",
        name: "AbandonoLeadsTuSeguro",
        props: true,
        component: AbandonoLeadsTuSeguro,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/:uuid",
        name: "cotizacion-detalle",
        props: true,
        component: CotizacionDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuario/crear",
        name: "crear-usuario",
        props: true,
        component: CrearUsuario,
        meta: {
          requiresAuth: true,
        },
      },
      //Editar info rápidamente desde el nav en "Perfil"
      {
        path: "/usuario/micuenta",
        name: "micuenta",
        props: true,
        component: MiCuenta,
        meta: {
          requiresAuth: true,
        },
      },
      //Componente vista Visualizacion de usuario desde "Perfil"
      {
        path: "/usuario/ver-micuenta",
        name: "verMiCuenta",
        props: true,
        component: VisualizacionMiCuenta,
        meta: {
          requiresAuth: true,
        },
      },
      // Visualizacion de usuario desde "TablaUsuarios"
      {
        path: "/usuario/ver-micuenta/:uuid",
        name: "verMiCuenta",
        props: true,
        component: VisualizacionMiCuenta,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuario/agente/:uuid",
        name: "agenteEdit",
        props: true,
        component: MiCuenta,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuario/verAgentes",
        name: "verAgentes",
        props: true,
        component: VerAgentes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuario/CotizaComoAgente",
        name: "CotizaAgentes",
        props: true,
        component: CotizaAgentes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/confirmaGNP/:ciane/confirma",
        name: "confirmaGNP",
        props: true,
        component: ConfirmaGNP,
      },
      {
        path: "/solicitudes",
        name: "solicitudes",
        props: true,
        component: SolicitudesHub,
      },
      {
        path: "/nueva-solicitud",
        name: "nueva-solicitud",
        props: true,
        component: SolicitudForm,
      },
      {
        path: "/seguimiento-solicitud/:uuid",
        name: "seguimiento-solicitud",
        props: true,
        component: SeguimientoSolicitud,
      },
      {
        path: "/solicitudes-operacion/:status",
        name: "solicitudes-operacion",
        props: true,
        component: MisSolicitudesOperacion,
      },
      {
        path: "/tarjeta-comercial",
        name: "tarjeta-comercial",
        props: true,
        component: TarjetaComercial,
      },
      {
        path: "/info-requerida/",
        name: "info-requerida",
        props: true,
        component: InfoRequeridaSolicitudes,
      },
      {
        path: "/actualizar-solicitud/:id",
        name: "actualizar-solicitud",
        props: true,
        component: ActualizarSolicitud,
      },
      {
        path: "/actualizar-emision/:id",
        name: "actualizar-emision",
        props: true,
        component: ActualizarEmision,
      },
      {
        path: "/crear-emision",
        name: "crear-emision",
        props: true,
        component: CrearPoliza,
      },

      // clientes modulo
      {
        path: "/lista-clientes",
        name: "lista-clientes",
        props: true,
        component: DashboardCliente,
      },
      {
        path: "/cliente-detalle/:uuid",
        name: "clienteDetalle",
        props: true,
        component: ClienteDetalle,
      },
      {
        path: "/actualizar-clientes/:id",
        name: "actualizar-clientes",
        props: true,
        component: ActualizarClientes,
      },
      {
        path: "/crear-clientes",
        name: "crear-clientes",
        props: true,
        component: CrearClientes,
      },

      // reportes masivos
      {
        path: "/administrador/reporte-sgmm",
        name: "reporteSGMM",
        props: true,
        component: ReporteSGMM,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-sura-sgmm",
        name: "reporteSuraSGMM",
        props: true,
        component: ReporteSuraSGMM,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-sura-movilidad",
        name: "reporteSuraMovilidad",
        props: true,
        component: ReporteSuraMovilidad,
        meta: {
          requiresAuth: true,
        },
      },

      //Reporte de Leads Tractos(Nuevo)
      {
        path: "/administrador/reporte-leads-tractos",
        name: "reporteLeadsTractos",
        props: true,
        component: reporteLeadsTractos,
        meta: {
          requiresAuth: true,
        },
      },
      //Modulo Productos
      {
        path: "/lista-productos",
        name: "listaProductos",
        props: true,
        component: VistaListaProductos,
      },
      {
        path: "/visualizacion-productos/:id",
        name: "visualizacionProductos",
        props: true,
        component: VistaVisualizacionProductos,
      },
      {
        path: "/edicion-productos/:id",
        name: "visualizacionProductosEditor",
        props: true,
        component: EdicionProductos,
      },
      {
        path: "/registro-productos",
        name: "registroProductos",
        props: true,
        component: RegistroProductos,
      },

      //Modulo Comisiones
      {
        path: "/lista-comisiones",
        name: "listaComisiones",
        props: true,
        component: VistaListaComisiones,
      },
      {
        path: "/visualizacion-comisiones/:uuid",
        name: "listaComisionesUUID",
        props: true,
        component: VistaVisualizacionComisiones,
      },
      {
        path: "/registro-comisiones",
        name: "registroComisiones",
        props: true,
        component: VistaRegistroComisiones,
      },
      //Modulo Cuotas
      {
        path: "/cuotas-hub",
        name: "cuotasHub",
        props: true,
        component: CuotasHub,
      },
      //Modulo Clientes
      {
        path: "/lista-clientes",
        name: "listaClientes",
        props: true,
        component: VistaListaClientes,
      },
      {
        path: "/visualizacion-clientes/:uuid",
        name: "visualizacionClientes",
        props: true,
        component: VistaVisualizacionClientes,
      },
      {
        path: "/editar-comisiones/:uuid",
        name: "edicionComisiones",
        props: true,
        component: VistaEditarComisiones,
      },
      //NUEVOS REPORTES
      {
        path: "/solicitudes-con-polizas",
        name: "solicitudesConPolizas",
        props: true,
        component: SolicitudesConPolizas,
      },
      {
        path: "/solicitudes-pendientes-aprobacion",
        name: "solicitudesPendientesAprobacion",
        props: true,
        component: SolicitudesPendientesAprobacion,
      },
      {
        path: "/sla-solicitudes",
        name: "slaSolicitudes",
        props: true,
        component: SlaSolicitudes,
      },
      {
        path: "/reporte-gerencial",
        name: "ReporteGerencial",
        props: true,
        component: ReporteGerencial,
      },
      {
        path: "/polizas-por-renovar",
        name: "polizasRenovar",
        props: true,
        component: PolizasPorRenovar,
      },
      {
        path: "/polizas-renovadas",
        name: "polizasRenovadas",
        props: true,
        component: PolizasRenovadas,
      },
      {
        path: "/polizas-emitidas",
        name: "polizasEmitidas",
        props: true,
        component: PolizasEmitidas,
      },
      {
        path: "/reporte-marcacion",
        name: "reporteMarcacion",
        props: true,
        component: ReporteMarcacion,
      },
      {
        path: "/reporte-emision-hogar",
        name: "reporteEmisionesAig",
        props: true,
        component: ReporteEmisionesAIG,
      },
      {
        path: "/reporte-emisiones-fallidas-hogar",
        name: "reporteEmisionesAigFallidas",
        props: true,
        component: ReporteEmisionesAIGFallidas,
      },
      {
        path: "/reporte-emision-hogar-app",
        name: "ReporteEmisionesAPP",
        props: true,
        component: ReporteEmisionesAPP,
      },

      {
        path: "/reporte-emision-incapacidad-ap",
        name: "reporteEmisionesIncapacidadAp",
        props: true,
        component: ReporteEmisionesAp,
      },
      {
        path: "/reporte-emision-vida",
        name: "reporteEmisionesVida",
        props: true,
        component: ReporteEmisionesVida,
      },
      {
        path: "/reporte-emision-vida-ahorro",
        name: "reporteEmisionesVidaAhorro",
        props: true,
        component: ReporteEmisionesVidaAhorro,
      },
      {
        path: "/reporte-emision-celular",
        name: "reporteEmisionesCelular",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/ReporteEmisionCelular.vue"
          ),
      },
      {
        path: "/reporte-emision-hospitalizacion",
        name: "reporteEmisionesHospitalizacion",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/ReporteEmisionHospitalizacion.vue"
          ),
      },
      {
        path: "/reporte-emision-asistencias",
        name: "reporteEmisionesAsistencias",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/ReporteEmisionAsistencias.vue"
          ),
      },
      {
        path: "/reporte-solicitudes",
        name: "reporteSolciitudes",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/ReporteSolicitudes.vue"
          ),
      },
      {
        path: "/reporte-redenciones",
        name: "reporteSolciitudes",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/ReporteRedenciones.vue"
          ),
      },

      {
        path: "/cobranza-polizas-activas",
        name: "cobranzaPolizasActivas",
        props: true,
        component: CobranzaPolizasActivas,
      },
      {
        path: "/reporte/cobranza/incapacidad-ap",
        name: "cobranzaIncapacidadAP",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/CobranzaIncapacidadAP.vue"
          ),
      },
      {
        path: "/reporte/cobranza/celular",
        name: "cobranzaSeguroCelular",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/CobranzaSeguroCelular.vue"
          ),
      },
      {
        path: "/reporte/cobranza/hospitalizacion",
        name: "cobranzaSeguroHospitalizacion",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/CobranzaSeguroHospitalizacion.vue"
          ),
      },
      {
        path: "/reporte/cobranza/asistencias",
        name: "cobranzaSeguroAsistencias",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/CobranzaSeguroAsistencias.vue"
          ),
      },
      {
        path: "/reporte/cobranza/vida-sura",
        name: "cobranzaVidaSura",
        props: true,
        component: () =>
          import("../views/pages/administrador/reportes/CobranzaVidaSura.vue"),
      },
      {
        path: "/reporte/cobranza/vida-ahorro-metlife",
        name: "cobranzaVidaSura",
        props: true,
        component: () =>
          import("../views/pages/administrador/reportes/CobranzaVidaAhorro.vue"),
      },
      {
        path: "/cuotas-pagadas",
        name: "cuotasPagadas",
        props: true,
        component: CuotasPagadas,
      },
      {
        path: "/cuotas-canceladas",
        name: "cuotasCanceladas",
        props: true,
        component: CuotasCanceladas,
      },
      {
        path: "/cuotas-vencidas",
        name: "cuotasVencidas",
        props: true,
        component: CuotasVencidas,
      },
      {
        path: "/cartera-agentes",
        name: "carteraAgentes",
        props: true,
        component: CarteraAgentes,
      },

      // bradescard
      {
        path: "/bradescard",
        name: "ViewBradescard",
        component: ViewBradescard,
      },
      {
        path: "/bradescard/leads-bradescard",
        name: "LeadsBradescard",
        props: true,
        component: LeadBradescard,
      },
      {
        path: "/bradescard/leads-bradescard-dashboard/:status",
        name: "DashboardLeads",
        props: true,
        component: DashboardLeads,
      },
      {
        path: "/bradescard/leads/dashboard",
        name: "DashboardLeadsBradescard",
        component: () =>
          import("../views/pages/bradescard/leads/DashboardLeads.vue"),
      },
      {
        path: "/bradescard/polizas-bradescard-dashboard/:status",
        name: "DashboardPolizas",
        props: true,
        component: DashboardPolizas,
      },
      {
        path: "/bradescard/leads-bradescard/lead/:uuid",
        name: "DetalleLeadBradescard",
        component: DetalleLeadsBradescard,
        props: true,
      },
      {
        path: "/reporte/cobranza/aig",
        name: "ReporteCobranzaAIG",
        component: ReporteCobranzaAIG,
      },
      {
        path: "/solicitud/:idSolicitud",
        name: "detalle-solicitud",
        component: () =>
          import("@/views/pages/solicitudes/DetalleSolicitud.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/proximamente",
        name: "proximamente",
        component: Proximamente,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/mis-solicitudes",
        name: "IndexSolicitudes",
        component: IndexSolicitudes,
      },
      {
        path: "/reporte-emision-asistencias-vigor",
        name: "reporteEmisionesAsistenciasVigor",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/ReporteEmisionAsistenciasVigor.vue"
          ),
      },
      {
        path:"/verCliente/:uuid",
        name: "verCliente",
        props: true,
        component: VerCliente
      },    
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const loginpath = window.location.pathname;
    if (localStorage.getItem("agenteAccessToken") == null) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
        query: { from: loginpath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
