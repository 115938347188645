var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('FiltrosCliente',{attrs:{"loading":_vm.loading},on:{"filtrosModificados":_vm.updateFilters}})],1),(
        _vm.$roleHelper.userHasRole([
          'ADMIN',
          'OPERACIONESASIST',
          'OPERADORASIST',
          'OPERADOR',
          'MANAGERCC',
          'OPERACIONES',
          'SUPASISTENCIAS' ])
      )?_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('openSolicitudForm')}}},[_vm._v("Crear Solicitud de Redención Sin Cliente")])],1)]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('ResultadosCliente',{attrs:{"loading":_vm.loading,"clientes":_vm.clientes,"totalRegistros":_vm.totalRegistros},on:{"paginacionModificada":_vm.paginacionModificada}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }